const logKey = 'ispui-logger';

export class Logger {
  static log(...args) {
    if (localStorage.getItem(logKey) === 'true') {
      console.log(...args);
    }
  }

  static group(groupName) {
    if (localStorage.getItem(logKey) === 'true') {
      console.groupCollapsed(groupName);
    }
  }

  static groupEnd() {
    if (localStorage.getItem(logKey) === 'true') {
      console.groupEnd();
    }
  }
}
