/**
 * Class for handle current color scheme
 */
export class ColorScheme {
  constructor() {
    this.init();
  }

  init() {
    let colorScheme = localStorage.getItem('isp_color_scheme');

    if (!colorScheme) {
      colorScheme = 'light';
      localStorage.setItem('isp_color_scheme', colorScheme);
    } else if (colorScheme === 'auto') {
      colorScheme = this.listenMediaQuery();
    }

    this.setDataAttribute(colorScheme);
  }

  listenMediaQuery() {
    const matchMedia = window.matchMedia('(prefers-color-scheme: dark)');

    matchMedia.addEventListener('change', event => {
      this.setDataAttribute(event.matches ? 'dark' : 'light');
    });

    if (matchMedia.matches) {
      return 'dark';
    } else {
      return 'light';
    }
  }

  setDataAttribute(value) {
    document.documentElement.setAttribute('data-color-scheme', value);

    this.updateRecaptchaTheme(value);
  }

  updateRecaptchaTheme(value) {
    const elem = document.querySelector('.g-recaptcha');

    if (!elem) {
      return;
    }

    elem.setAttribute('data-theme', value);
  }
}
