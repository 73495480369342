import {
  applyPolyfills,
  defineCustomElements as defineInputElements,
} from '@ispui/input/loader';
import { defineCustomElements as defineButtonElements } from '@ispui/button/loader';
import { defineCustomElements as defineSelectElements } from '@ispui/select/loader';
import { defineCustomElements as defineTooltipElements } from '@ispui/tooltip/loader';
import { defineCustomElements as definePreloaderRoundElements } from '@ispui/preloader-round/loader';

import { ColorScheme } from './color-scheme';
import { LoginForm } from './login-form';

// Sentry use is disabled for now till ispmanager's Sentry service starts working

// import * as Sentry from '@sentry/browser';
// import { Integrations } from '@sentry/tracing';

// Sentry.init({
//   dsn: 'https://d4302a4c97774ba997860327039e3eac@sentry.ispsystem.net/4',
//   integrations: [new Integrations.BrowserTracing()],

//   tracesSampleRate: 1.0,
// });

applyPolyfills().then(() => {
  defineInputElements();
  defineButtonElements();
  defineSelectElements();
  defineTooltipElements();
  definePreloaderRoundElements();
});

document.addEventListener('DOMContentLoaded', function () {
  new ColorScheme();
  new LoginForm();
});
